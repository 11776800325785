/* 
    Componente: AppAuthorization
    Descripción: Verificación de autenticación de usuario, de lo contrario te devuelve al login para iniciar sesión
*/

import React, { Component } from 'react';
import AppUtils from "@app/AppUtils";
import { matchRoutes } from 'react-router-config';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import AppContext from 'AppContext';

class AppAuthorization extends Component {
    constructor(props, context) {
        super(props);
        const { routes } = context;
        this.state = {
            accessGranted: true,
            routes
        };
    }

    componentDidMount() {
        if (!this.state.accessGranted) {
            this.redirectRoute();
        }
    }

    componentDidUpdate() {
        if (!this.state.accessGranted) {
            this.redirectRoute();
        }
    }

    static getDerivedStateFromProps(props, state) {
        const { location, userRole } = props;
        const { pathname } = location;

        const matched = matchRoutes(state.routes, pathname)[0];

        return {
            accessGranted: matched ? AppUtils.hasPermission(matched.route.auth, userRole) : true
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return nextState.accessGranted !== this.state.accessGranted;
    }

    redirectRoute() {
        const { location, userRole, history } = this.props;
        const { pathname, state } = location;
        const redirectUrl = state && state.redirectUrl ? state.redirectUrl : `${process.env.PUBLIC_URL}`;
        if (localStorage.getItem("access_token")&& pathname=="/login") {
        console.log("TT01",pathname);
            history.push({
                pathname: `${process.env.PUBLIC_URL}/dashboard`,
                state: { redirectUrl: pathname }
            }); 
        }else{
            if (!userRole || userRole.length === 0) {
                history.push({
                    pathname: `${process.env.PUBLIC_URL}/login`,
                    state: { redirectUrl: pathname }
                });
            } else {
                history.push({
                    pathname: redirectUrl
                });
            }
        }
        
    }

    render() { return this.state.accessGranted ? <React.Fragment>{this.props.children}</React.Fragment> : null; }
}

function mapStateToProps({ auth }) {
    return {
        userRole: auth.user.role
    }
}

AppAuthorization.contextType = AppContext;

export default withRouter(connect(mapStateToProps)(AppAuthorization));