import * as Actions from '../actions';

const initialState = {
    success: false,
    loader:false,
    error: null
};

const login = function (state = initialState, action) {
    switch (action.type) {
        case Actions.LOGIN_SUCCESS:
            {
                return {
                    ...initialState,
                    success: true,
                    loader: false
                };
            }
            case Actions.LOGIN_LOADER:{
                return {                    
                        ...initialState,
                        loader: true                    
                };
            }
        case Actions.LOGIN_ERROR:
            {
                return {
                    success: false,
                    error: action.payload,
                    loader: false
                };
            }
        default:
            {
                return state
            }
    }
};

export default login;