import axios from "axios"
import { toast } from "react-toastify"
import * as Actionss from "components/auth/store/actions"

export const GET_MENUS_BY_USER = "[ACCESS_CONTROL] GET USER_MENUS"

export const CRUD_USER_MENUS = "[ACCESS_CONTROL] CRUD ACCESS"

export function getMenusByUserId(userId) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/gmenususuario/${userId}`)
  return (dispatch) => {
    dispatch({ type: GET_MENUS_BY_USER, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actionss.logoutUser())
        }
        return
      }

      if (parseInt(response.data.status) === 200) {
        dispatch({ type: GET_MENUS_BY_USER, payload: { loading: false, data: response.data.detalles } })
      } else {
        dispatch({ type: GET_MENUS_BY_USER, payload: { loading: false, data: null } })
      }

      setTimeout(() => {
        dispatch({ type: GET_MENUS_BY_USER, payload: { loading: false, data: null } })
      }, 50)

    }).catch((error) => {
      dispatch({ type: GET_MENUS_BY_USER, payload: { loading: false, data: null } })
      toast.error("Error al obtener los menus del usuario")
      console.log(error)
    })
  }
}

export function saveUserMenu(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/rmenuusuario`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_USER_MENUS, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actionss.logoutUser())
        }
        return
      }

      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_USER_MENUS, payload: { loading: false, crud: true } })
      } else {
        toast.error("Error al asginar el menú al usuario")
        dispatch({ type: CRUD_USER_MENUS, payload: { loading: false, crud: false } })
      }

      setTimeout(() => {
        dispatch({ type: CRUD_USER_MENUS, payload: { loading: false, crud: false } })
      }, 50)

    }).catch((error) => {
      dispatch({ type: CRUD_USER_MENUS, payload: { loading: false, crud: false } })
      toast.error("Error al asginar el menú al usuario")
      console.log(error)
    })
  }
}

export function deleteUserMenu(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/emenuusuario`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_USER_MENUS, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actionss.logoutUser())
        }
        return
      }

      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_USER_MENUS, payload: { loading: false, crud: true } })
      } else {
        toast.error("Error al remover el menú del usuario")
        dispatch({ type: CRUD_USER_MENUS, payload: { loading: false, crud: false } })
      }

      setTimeout(() => {
        dispatch({ type: CRUD_USER_MENUS, payload: { loading: false, crud: false } })
      }, 50)

    }).catch((error) => {
      dispatch({ type: CRUD_USER_MENUS, payload: { loading: false, crud: false } })
      toast.error("Error al remover el menú del usuario")
      console.log(error)
    })
  }
}
