
export const mapProductRowsToFilter = (rows = [], products = []) => {
  if (!rows || rows.length === 0) return rows;

  if (!products || products.length === 0) return rows;

  const mappedRows = rows.map((row) => {
    const product = products.find(({ producto_id }) => producto_id == row.producto_id) ?? null;

    return {
      ...row,
      denominacion: product?.denominacion ?? '',
      codigo_producto: product?.codigo_producto ?? '',
    };
  });

  return mappedRows; // Retornamos el nuevo array transformado
};
