import * as Actions from "../../actions/app"

const initialState = {
  products: null,

  loading_get_products: false,
  get_products: null,

  loading_products_by_filter: false,
  products_by_filter: null,

  sorts: null,
  subsorts: null,
  brands: null,
  materials: null,
  presentations: null,
  typeexistences: null,
  unitmeasures: null,

  loading_unit_measures_product: false,
  unit_measures_product: null,
  set_crud_unit_measures_by_product: null,

  loading_last_cost_price_product: false,
  last_cost_price_product: null,

  product: null,
  loading_crud_product: false,
  crud_product: false,

  loading_stock_product: false,
  stock_product: null,

  set_crud_sort: null,
  set_crud_subsort: null,
  set_crud_unitmeasure: null,
  set_crud_brand: null,
  set_crud_material: null,
  set_crud_presentacion: null,
  set_crud_typeexistence: null,

  loading_complements_product: false,
  complements_product: null,

  loading_save_complement_product: false,
  saved_complement_product: false,

  loading_delete_complement_product: false,
  deleted_complement_product: false,

  loading_product_details: false,
  product_details: null,
}

const productsReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_ALL_PRODUCTS: {
      return {
        ...state,
        products: action.payload,
      }
    }

    case Actions.LOADING_GET_PRODUCTS: {
      return {
        ...state,
        loading_get_products: action.payload,
      }
    }

    case Actions.GET_PRODUCTS: {
      return {
        ...state,
        get_products: action.payload.detalles,
      }
    }

    case Actions.GET_PRODUCTS_BY_FILTER: {
      return {
        ...state,
        loading_products_by_filter: action.payload.loading,
        products_by_filter: action.payload.data
      }
    }

    case Actions.GET_UNIT_MEASURES_PRODUCT: {
      return {
        ...state,
        loading_unit_measures_product: action.payload.loading,
        unit_measures_product: action.payload.data
      }
    }

    case Actions.GET_ALL_SORTS: {
      return {
        ...state,
        sorts: action.payload
      }
    }
    case Actions.GET_ALL_SUBSORTS: {
      return {
        ...state,
        subsorts: action.payload
      }
    }
    case Actions.GET_ALL_BRANDS: {
      return {
        ...state,
        brands: action.payload,
      }
    }
    case Actions.GET_ALL_MATERIALS: {
      return {
        ...state,
        materials: action.payload
      }
    }
    case Actions.GET_ALL_PRESENTATIONS: {
      return {
        ...state,
        presentations: action.payload
      }
    }
    case Actions.GET_ALL_TYPEEXISTENCES: {
      return {
        ...state,
        typeexistences: action.payload
      }
    }
    case Actions.GET_ALL_UNITMEASURES: {
      return {
        ...state,
        unitmeasures: action.payload
      }
    }
    case Actions.GET_DATA_PRODUCT: {
      return {
        ...state,
        product: action.payload,
      }
    }
    case Actions.GET_LAST_COST_PRICE: {
      return {
        ...state,
        loading_last_cost_price_product: action.payload.loading,
        last_cost_price_product: action.payload.data,
      }
    }
    case Actions.STOCK_RODUCT: {
      return {
        ...state,
        loading_stock_product: action.payload.loading,
        stock_product: action.payload.stock,
      }
    }
    case Actions.CRUD_PRODUCT: {
      return {
        ...state,
        loading_crud_product: action.payload.loading,
        crud_product: action.payload.crud,
      }
    }
    case Actions.CRUD_UNIT_MEASURES_BY_PRODUCT: {
      return {
        ...state,
        set_crud_unit_measures_by_product: action.payload,
      }
    }
    case Actions.CRUD_SORT: {
      return {
        ...state,
        set_crud_sort: action.payload,
      }
    }
    case Actions.CRUD_SUBSORT: {
      return {
        ...state,
        set_crud_subsort: action.payload,
      }
    }
    case Actions.CRUD_UNITMEASURES: {
      return {
        ...state,
        set_crud_unitmeasure: action.payload,
      }
    }
    case Actions.CRUD_BRAND: {
      return {
        ...state,
        set_crud_brand: action.payload,
      }
    }
    case Actions.CRUD_MATERIAL: {
      return {
        ...state,
        set_crud_material: action.payload,
      }
    }
    case Actions.CRUD_PRESENTATION: {
      return {
        ...state,
        set_crud_presentacion: action.payload,
      }
    }
    case Actions.CRUD_TYPEEXISTENCE: {
      return {
        ...state,
        set_crud_typeexistence: action.payload,
      }
    }
    case Actions.GET_COMPLEMENTS_PRODUCT: {
      return {
        ...state,
        loading_complements_product: action.payload.loading,
        complements_product: action.payload.data,
      }
    }
    case Actions.SAVE_COMPLEMENT_PRODUCT: {
      return {
        ...state,
        loading_save_complement_product: action.payload.loading,
        saved_complement_product: action.payload.saved,
      }
    }
    case Actions.DELETE_COMPLEMENT_PRODUCT: {
      return {
        ...state,
        loading_delete_complement_product: action.payload.loading,
        deleted_complement_product: action.payload.deleted,
      }
    }
    case Actions.GET_PRODUCT_DETAILS: {
      return {
        ...state,
        loading_product_details: action.payload.loading,
        product_details: action.payload.data,
      }
    }
    default: {
      return state
    }
  }
}
export default productsReducer
