import * as Actions from "../../actions/app"
const initialState = {
  types_payments: [],
  types_payments_froms: [],
  series: [],
  correlativo: {},
  coins_types: [],
  price_dollar: null,

  loading_sales: false,
  sales: null,
  total_pages_sales: 1,
  total_registers: 0,

  sales_by_distpach: null,
  total_pages_sales_by_dispatch: 1,

  loading_crud_sale: false,
  crud_sale: false,

  loading_sale: false,
  sale: null,

  reservation_residence: [],
  reservation_residence_consumption_stay: {},
  report_sales: null,

  persons_credits: null,
  credits_by_person: [],
  sale_credit_true: null,
  saved_credit: false,

  saved_pay_of_credit: false,

  payments_person_of_credit: [],
  loading_pp_of_credit: false,

  is_cancel_pay_of_credit: false,
  is_cancel_credit: false
}
const salesReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_REPORT_SALES: {
      return {
        ...state,
        report_sales: action.payload,
      }
    }
    case Actions.CRUD_SALE: {
      return {
        ...state,
        loading_crud_sale: action.payload.loading,
        crud_sale: action.payload.crud,
      }
    }
    case Actions.GET_SALE: {
      return {
        ...state,
        loading_sale: action.payload.loading,
        sale: action.payload.data,
      }
    }
    case Actions.LOADING_SALES: {
      return {
        ...state,
        loading_sales: action.payload,
      }
    }
    case Actions.GET_SALES: {
      return {
        ...state,
        sales: action.payload.detalles,
        total_pages_sales: action.payload.totalPaginas,
        total_registers: action.payload.totalRegistros,
      }
    }
    case Actions.GET_SALES_BY_DISPATCH: {
      return {
        ...state,
        sales_by_distpach: action.payload.detalles,
        total_pages_sales_by_dispatch: action.payload.totalPaginas,
      }
    }
    case Actions.GET_PRICE_DOLLAR_SALES: {
      return {
        ...state,
        price_dollar: action.payload,
      }
    }
    case Actions.GET_DATA_COIN_TYPE: {
      return {
        ...state,
        coins_types: [...action.payload],
      }
    }
    case Actions.GET_DATA_CORRELATIVE_SERIES_VOUCHER: {
      return {
        ...state,
        correlativo: action.payload[0],
      }
    }
    case Actions.GET_DATA_SERIES_VOUCHER: {
      return {
        ...state,
        series: [...action.payload],
      }
    }
    case Actions.GET_DATA_TYPE_PAYMENT: {
      return {
        ...state,
        types_payments: [...action.payload],
      }
    }
    case Actions.GET_DATA_TYPE_PAYMENT_FROMS: {
      return {
        ...state,
        types_payments_froms: [...action.payload],
      }
    }
    case Actions.GET_DATA_RESERVATION_RESIDENCE: {
      return {
        ...state,
        reservation_residence: [...action.payload],
      }
    }
    case Actions.GET_DATA_RESERVATION_RESIDENCE_CONSUMPTION_STAY: {
      return {
        ...state,
        reservation_residence_consumption_stay: action.payload,
      }
    }
    case Actions.SALE_CREDIT_TRUE: {
      return {
        ...state,
        sale_credit_true: action.payload,
      }
    }
    case Actions.SAVE_CREDIT: {
      return {
        ...state,
        saved_credit: true,
        sale_credit_true: null,
      }
    }
    case Actions.RESET_CRUD_CREDIT: {
      return {
        ...state,
        saved_credit: false,
      }
    }
    case Actions.GET_PERSONS_CREDITS: {
      return {
        ...state,
        persons_credits: action.payload,
      }
    }
    case Actions.GET_CREDITS_BY_PERSON: {
      return {
        ...state,
        credits_by_person: action.payload,
      }
    }
    case Actions.SAVE_PAY_OF_CREDIT: {
      return {
        ...state,
        saved_pay_of_credit: true,
      }
    }
    case Actions.RESET_CRUD_PAY_OF_CREDIT: {
      return {
        ...state,
        saved_pay_of_credit: false,
      }
    }
    case Actions.GET_PAYMENTS_PERSON_OF_CREDIT: {
      return {
        ...state,
        payments_person_of_credit: action.payload,
        loading_pp_of_credit: false,
      }
    }
    case Actions.LOADING_PP_OF_CREDIT: {
      return {
        ...state,
        loading_pp_of_credit: true,
      }
    }
    case Actions.CANCEL_PAY_OF_CREDIT: {
      return {
        ...state,
        is_cancel_pay_of_credit: action.payload
      }
    }
    case Actions.CANCEL_CREDIT: {
      return {
        ...state,
        is_cancel_credit: action.payload
      }
    }

    default: {
      return state
    }
  }
}
export default salesReducer
