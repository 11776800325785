import React, { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import clsx from 'clsx';
import * as authActions from 'components/auth/store/actions';
import { useDispatch, useSelector } from "react-redux";
import reducer from 'components/auth/store/reducers';
import withReducer from 'store/withReducer';

const Login = () => {
  const dispatch = useDispatch();
  const login = useSelector(({ auth }) => auth.login);
  const loader = useSelector(({ auth }) => auth.login.loader);
  const { handleSubmit, register, errors } = useForm();
  const formRef = useRef(null);
  const onSubmit = values => {
    dispatch(authActions.submitLogin(values.username, values.password));
  }

  useEffect(() => {
    document.body.classList.add('hold-transition');
    document.body.classList.add('login-page');

    return () => { document.body.className = ''; }
  }, []);

  return (
    <div className="login-box" style={{ width: 360 }}>
      <div className="login-logo">
        <a href="/" style={{ textDecoration: 'none', fontWeight: 600 }}>
          {process.env.REACT_APP_ESTABLISHMENT_NAME || 'Trade'}
        </a>
      </div>
      <div className="card table-radius">
        <div className="card-body">
          <p className="login-box-msg">Ingrese sus credenciales para acceder a nuestro sistema</p>
          {(login.error !== null) && (<div className="alert alert-danger">
            <h5><i className="icon fas fa-ban"></i> Mensaje del sistema</h5>
            {login.error}
          </div>)}
          <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
            <div className={clsx("input-group", errors.username ? '' : 'mb-3')}>
              <input type="text" className={clsx("form-control", errors.username ? 'is-invalid' : '')} name="username" placeholder="Ingrese usuario" autoFocus={true} ref={register({
                required: '* Campo obligatorio'
              })} />
              <div className="input-group-append">
                <div className="input-group-text">
                  <span className="fas fa-user"></span>
                </div>
              </div>
            </div>
            {errors.username && (<p className="text-danger mb-2 small">{errors.username.message}</p>)}
            <div className={clsx("input-group", errors.password ? '' : 'mb-3')}>
              <input type="password" className={clsx("form-control", errors.password ? 'is-invalid' : '')} name="password" placeholder="Ingrese contraseña" ref={register({
                required: '* Campo obligatorio'
              })} />
              <div className="input-group-append">
                <div className="input-group-text">
                  <span className="fas fa-lock"></span>
                </div>
              </div>
            </div>
            {errors.password && (<p className="text-danger mb-2 small">{errors.password.message}</p>)}
            <div className="row">
              <div className="col-6 offset-6">
                <button type="submit" className="btn btn-success btn-block"
                  disabled={loader}
                >
                  {loader ? '. . .' : 'Iniciar sesión'}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <p className="text-center">
        <span>
          &copy;{new Date().getFullYear()}, Equipo ACCORD
        </span>
      </p>
    </div>);
};

export default withReducer('auth', reducer)(Login);